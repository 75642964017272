import { unlock, replace_body_state } from './utils';
import mainSquare from "./mainSquare";

let twoOut_oneIn = (status=false) => {
    let nodes = window.ANI.el;
    let curernt_index = window.ANI.idx.subsection;

    if(status) {
        curernt_index += 1;
        window.ANI.idx.subsection = curernt_index;
    };
    let next_index = curernt_index - 1;

    let hideAnim = new Promise((resolve, reject) => {
        !status ? mainSquare('zoom', resolve) : resolve();
        let properties = {
            opacity: [0, 1],
            transform: ['translateY(100%)', 'translateY(0)'],
        };
        let duration = 200;

        let collection = Array.from(nodes.fa21).reverse();
        let len = collection.length - 1;

        collection.forEach((node, index) => {
            if (index < len) {
                node.velocity(properties, {
                    duration: duration,
                    delay: duration * index,
                });
            } else {
                node.velocity(properties, {
                    duration: duration,
                    delay: duration * index,
     /*               complete: () => {
                       !status ? mainSquare('zoom', resolve) : resolve();
                    }*/
                });
            }
        });
    });

/*    let hideBg = new Promise((resolve, reject) => {
        nodes.fa22.velocity({ opacity: [0, 1] }, {
            duration: 250,
            complete: () => {
                resolve();
            },
        });
    });*/

    hideAnim
 /*   .then(() => hideBg)*/
    .then(() => {
        nodes.promo.style.transform = `translateY(-${next_index * 100}%)`;
      /*  !status ? nodes.promo_slides[1].style.top = '100%' : null;*/
        nodes.fa03
            .velocity({
            /*    opacity: [1, 0],*/
            }, {
                duration: 100,
                complete: function(){

                    nodes.fa11.velocity({ opacity: [1, 0] }, { duration: 250 })
                              .velocity({ transform: ['translateX(0)', 'translateX(-100vw)'] }, { duration: 1000 });

                    nodes.fa12.velocity({ opacity: [1, 0] }, { duration: 250 })
                              .velocity({ transform: ['translateX(0)', 'translateX(100vw)'] }, {
                        duration: 1000,
                        complete: function() {
                            window.ANI.idx.subsection = next_index;
                            replace_body_state();
                            unlock();
                        }
                    });
                },
            });
    });
};

export default twoOut_oneIn;
