import axios from 'axios';
import TurbolinksAdapter from 'vue-turbolinks';
// import { createApp } from 'vue/dist/vue.esm-bundler';
import Vue from 'vue/dist/vue.esm';
Vue.use(TurbolinksAdapter);

const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const re_email = /\S+@\S+\.\S+/;

const formInvestors = {
    el: '#form_investors',
    // template: '#form-investors-template',
    data() {
        return {
            message: '',
            username: '',
            email: '',
            screen: 'form',
            // screen: 'post-message',
            choice_list: window.investors_forms.choices,
            choice_value: window.investors_forms.choices[0],
            show_list: false,
        }
    },

    mounted: function(){
        document.addEventListener('click', (event) => {
            if (!event.target.closest('.form-ps-select')) {
                this.show_list = false;
            }
        });
    },

    computed: {
        validate: function() {
            // return this.message === '' ||
            //        this.username === '' ||
            //        !re_email.test(this.email);
            return this.username === '' ||
                !re_email.test(this.email);
        }
    },

    methods: {
        label_hidden: function(event) {
            event.target.closest('.form-line').classList.add('form-label-hide');
        },
        label_show: function(event) {
            if (event.target.value === '') {
                event.target.closest('.form-line').classList.remove('form-label-hide');
            }
        },
        show_choice: function() {
            this.show_list = !this.show_list;
        },
        set_choice_value: function(event) {
            this.choice_value = event.target.innerHTML;
            this.show_list = false;
        },
        checkme: function(event) {
            let self = this;
            let input = event.target;
            let parent = input.parentNode;

            if (input.type === 'text') {
                if (self.username === '') {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }
            } else if (input.type === 'email') {
                if( !re_email.test(self.email) ) {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }
            }
        },
        send: function(event) {
            const self = this;
            const data = {
                username: self.username,
                email: self.email,
                message: self.message,
                choice_value: self.choice_value,
            };
            document.querySelector('.submit-investors').style.visibily = 'hidden';
            axios.post(event.target.action, data)
                .then((response) => {
                    const resp = response.data;
                    if (resp.status === 'ok') {
                        self.screen = 'post-message'
                    }
                }).catch((error) => {
                    console.error(error);
                });
        }
    }
}

let app = null;
const form_investors = () => {
    if (document.getElementById('form_investors') || !app) {
        app = new Vue(formInvestors);
    }

    // document.addEventListener('turbolinks:click', () => {
    //     if (app) {
    //         app.$destroy();
    //         app = null;
    //     }
    // })
};

export default form_investors;
