import {background} from "./background";

function menu_light() {
   let canvas = document.getElementById('canvas-menu');
   let ctx = canvas.getContext('2d');
   let menu_text = document.querySelectorAll('.menu-text');
   let nav_node = document.querySelector('.global-nav-box');

   canvas.width = window.innerWidth;
   canvas.height = window.innerHeight;

   let position = window.innerWidth;
   let start = 10;
   let mask = 0.7;

   let Lamp = window.illuminated.Lamp,
       Vec2 = window.illuminated.Vec2,
       Lighting = window.illuminated.Lighting,
       DarkMask = window.illuminated.DarkMask;

/*   let light = new Lamp({
      position: new Vec2(position, 0),
      distance: 1,
      //color: 'rgba(250,250,250,0.3)',
      color: 'rgba(200,200,200, 0.3)',
      diffuse: 0.5,
      roughness: 0.5,
   });*/

   let light2 = new Lamp({
      position: new Vec2(position + 50, - 50),
      distance: 1,
      color: 'rgba(255, 255, 255, 0.8)',
      //color: 'rgba(170, 170, 170, 0.4)',
      radius: 200,
      diffuse: 0.8
   });

/*   let lighting = new Lighting({
      light: light,
   });*/
   let lighting2 = new Lighting({
      light: light2,
   });
   let darkmask = new DarkMask({ lights: [light2], color: ['rgba(20, 20, 20, 0.7)'] });


   function draw(value, opacity=0.6) {
      ctx.clearRect(0, 0, window.innerWidth,  window.innerHeight);
      ctx.beginPath();
      /*light.distance = value;*/
      light2.distance = value;
      light2.radius = value / 12;
      darkmask.color=[`rgba(20, 20, 20, ${opacity})`]
/*      lighting.compute(canvas.width, canvas.height);*/
      lighting2.compute(canvas.width, canvas.height);
      darkmask.compute(canvas.width, canvas.height);
    /*  ctx.globalCompositeOperation = "lighter";
      lighting.render(ctx);*/
      lighting2.render(ctx);
      darkmask.render(ctx);
   }

   function animateOpen() {
      let param = window.innerWidth < 520 ? window.innerHeight / 1.2 : window.innerWidth / 1.15;
      draw(start, mask)
      start += 100;
      //mask -= 0.02;
     if(mask < 0.4) mask = 0.4;
      if(start > param) start = param;
      let id =  requestAnimationFrame(animateOpen);
      if(start == param) {
         window.cancelAnimationFrame(id);
         appearText();
      }
   }
   draw(10);

   function animateClose() {
      start -= 100;
      if(start < 100 ) start = 1;
      draw(start);
      let id = requestAnimationFrame(animateClose);
      if(start == 1) {
         window.cancelAnimationFrame(id);
         start = 1;
      }
   }

   function appearText() {
      let properties = {
         opacity: [1, 0],
         transform: ['translateY(0)', 'translateY(100%)'],
      };
      let duration = 200;
      menu_text.forEach((node, idx) => {
         node.velocity(properties, {
            duration: duration,
            delay: duration * idx,
         })
      });
   }

   function hideText() {
      let properties = {
         opacity: [0, 1],
         transform: ['translateY(100%)', 'translateY(0)'],
      };
      let duration = 200;
      let len = menu_text.length - 1;
      let collection = Array.from(menu_text).reverse();

      collection.forEach((node, idx) => {
         if(idx < len) {
            node.velocity(properties, {
               duration: duration,
               delay: duration * idx,
            })
         } else {
            node.velocity(properties, {
               duration: duration,
               delay: duration * idx,
               complete: () => {
                  animateClose();
                  nav_node
                      .velocity({opacity: 0}, {duration: 700})
                      .velocity({
                         transform: ['translateX(100%)', 'translateX(0%)']
                      }, {
                         duration: 10,
                      });

               }
            })
         }
      });
   }

   function eventNav() {
      let properties = {
         opacity: [0, 1],
         transform: ['translateY(100%)', 'translateY(0)'],
      };
      menu_text.forEach((node, idx) => {
         node.velocity(properties);
      });
      nav_node.velocity({opacity: 0})
      start = 10;
   }


   document.querySelector('.top-plus').addEventListener('click', () => {
     setTimeout(() => animateOpen(), 300);
     background('menu', 0.9);
   });

   document.querySelector('.close-nav').addEventListener('click', () => {
      //animateClose();
      hideText();
   });

   document.querySelectorAll('*[data-action="nav"]').forEach((x) => x.addEventListener('click', eventNav));
}
export default menu_light;

