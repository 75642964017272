import  illuminated  from './illuminated';

function mouse_light() {
// window.addEventListener('DOMContentLoaded', () => {
    // console.log('turbolinks:load')
    // document.addEventListener('turbolinks:load', () => {
        let canvas = document.getElementById('mouse-light');
        if(!canvas) {
            return;
        }

        let ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        ctx.filter = "brightness(200%) opacity(90%) blur(5px)";

        let position = window.innerWidth;
        let start = -50;
        let widthLight = window.innerWidth < 500 ? window.innerHeight / 1.4 : window.innerWidth / 1.6;

        let Lamp = window.illuminated.Lamp;
        let Vec2 = window.illuminated.Vec2;
        let Light = window.illuminated.Lighting;

        let light = new Lamp({
            position: new Vec2(window.innerWidth, 0),
            distance: widthLight,
            color: "rgba(200, 200, 200, 0.25)",
            //radius: 150,
            diffuse: 0.5
        });


        let lighting = new Light({
            light: light,
        });


         lighting.compute(canvas.width, canvas.height);
         lighting.render(ctx);

         function draw(position) {
             ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
             ctx.beginPath();
             light.position = new Vec2(position, 0);
             lighting.compute(canvas.width, canvas.height);
             lighting.render(ctx);
         };


        function animateLeft() {
            draw(position);
            position -= 100;
            if(position < -50) position = -50;
            let id = requestAnimationFrame(animateLeft);
            if(position === -50) {
                window.cancelAnimationFrame(id);
                position = window.innerWidth;
            }
        };

        function animateRight() {
          draw(start);
          start += 100;
          if(start > window.innerWidth + 50) start = window.innerWidth + 50;
          let id = requestAnimationFrame(animateRight);
          if(start === window.innerWidth + 50) {
              window.cancelAnimationFrame(id);
              start = 0;
          }
        };

        let leftSidePage = document.querySelector('.fa01');
        let rightSidePage = document.querySelector('.fa02');

        document.addEventListener('mousemove', (event) => {
            if (event.clientX < window.innerWidth / 2 && !leftSidePage.classList.contains('activate')) {
                animateLeft();
                leftSidePage.classList.add('activate');
                rightSidePage.classList.remove('activate');
            }
            if (event.clientX > window.innerWidth / 2 && !rightSidePage.classList.contains('activate')){
                animateRight();
                rightSidePage.classList.add('activate');
                leftSidePage.classList.remove('activate');
            }
        });
//     });
};



export default mouse_light;

