// import Swiper from 'swiper/bundle';
// let commentsSwiper;
// let comment_indexes;
// let box;

// function get_to_review_slide() {
//     let index_slide = comment_indexes.indexOf(this.dataset.commentId);
//     box.scrollTo({
//         top: parseInt(document.querySelector('.comments-box').offsetTop),
//         behavior: 'smooth',

//     })
//     commentsSwiper.slideTo(index_slide, 100);
// }

import Velocity from 'velocity-animate';

const activeClass = 'partners-list-item_active';
let partners_list;
let animation_lock = false;
let active_node;

function mob_show_review(event) {
    let target = event.currentTarget || event.target;

    if (!target.classList.contains('partners-head_with-review')) {
        target = target.closest('.partners-head_with-review');
    }

    const box = target.closest('.partners-list-item');
    const logo = box.querySelector('.partners-logo');
    const content = box.querySelector('.partners-body');
    const height = content.querySelector('.comment').offsetHeight;

    box.classList.add(activeClass);

    active_node = box;

    logo.style.opacity = '0';
    content.velocity({height: `${height}px`}, {
        duration: 500,
        complete: () => {
            // box.scrollIntoView()
            animation_lock = false;
        },
    });
}

// function mob_switch_review(event) {
//     const logo = active_node.querySelector('.partners-logo');
//     const content = active_node.querySelector('.partners-body');
//     content.velocity({height: '0px'}, {
//         duration: 500,
//         complete: () => {
//             active_node.classList.remove(activeClass);
//             mob_show_review(event);
//             logo.style.opacity = '1';
//         },
//     });
// }

function hide_review() {
    const self  = this;
    const parent = self.closest('.partners-list-item');

    if (parent && parent.classList.contains(activeClass)) {
        const logo = parent.querySelector('.partners-logo');
        const content = parent.querySelector('.partners-body');

        content.velocity({height: '0px'}, {
            duration: 500,
            complete: () => {
                parent.classList.remove(activeClass);
                logo.style.opacity = '1';
            },
        });
    }
    // console.log(active_node === parent);
}

function normalizeCanvas() {
    try {
        let container = document.querySelector('.partners-box');
        let canvas = container.parentNode.querySelector('canvas');
        canvas.height = container.offsetHeight;
        canvas.style.height = `${container.offsetHeight}px`;
    } catch(e) {
        // empty
    }
}

function scrollToRewiew(node) {
    let li = node.closest('.partners-list-item')
    // let container = li.closest('.section-auto');
    let container = li.closest('.autoheight-slide');
    container.scrollTo({
        top: li.offsetTop,
        behavior: 'smooth',
    });
}


function show_review(event) {
    let target = event.currentTarget || event.target;

    if (!target.classList.contains('partners-head_with-review')) {
        target = target.closest('.partners-head_with-review');
    }

    const box = target.closest('.partners-list-item');
    const logo = box.querySelector('.partners-logo');
    const content = box.querySelector('.partners-body');
    const height = content.querySelector('.comment').offsetHeight;

    box.classList.add(activeClass);
    active_node = box;

    scrollToRewiew(target);

    logo.velocity({
        left: `${box.offsetWidth - logo.offsetWidth}px`,
    }, {
        duration: 500,
        complete: () => {
            content.velocity({height: `${height}px`}, {
                duration: 500,
                complete: () => {
                    // normalizeCanvas();
                    animation_lock = false;
                },
            });
        }
    });
}


function switch_review(event) {
    const logo = active_node.querySelector('.partners-logo');
    const content = active_node.querySelector('.partners-body');
    content.velocity({height: '0px'}, {
        duration: 500,
        complete: () => {
            active_node.classList.remove(activeClass);
            setTimeout(() => { show_review(event) }, 250);
            logo.velocity({
                left: '0px',
            }, {
                duration: 500,
                complete: () => {

                },
            });
        },
    });
}

function review_balancer(event) {
    if (animation_lock) {
        return;
    }

    animation_lock = true;

    if (document.documentElement.clientWidth >= 768) {
        active_node ? switch_review(event) : show_review(event);
    } else {
        // active_node ? mob_switch_review(event) : mob_show_review(event);
        mob_show_review(event);
    }

}

function comment_slider() {
    partners_list = document.querySelectorAll('.partners-head_with-review');
    partners_list.forEach(item => item.addEventListener('click', review_balancer));

    if (document.documentElement.clientWidth < 768) {
        document.querySelectorAll('.comment-info').forEach((x) => x.addEventListener('click', hide_review));
        document.querySelectorAll('.close-button-placeholder').forEach((x) => x.addEventListener('click', hide_review));
    }

    // if (document.querySelector('.comments-swiper-container')) {
    //     box = document.querySelector('.section[data-el="partners"]');
    //     commentsSwiper = new Swiper('.comments-swiper-container', {
    //         loop: false,
    //         navigation: {
    //             nextEl: '.comments-control_next',
    //             prevEl: '.comments-control_prev',
    //           },
    //     });
    //     comment_indexes = Array.from(document.querySelectorAll('.comments-swiper-container .swiper-slide')).map((x) => { return x.dataset.reviewId });
    //     document.querySelectorAll('.link-partner-comment').forEach((x) => x.addEventListener('click', get_to_review_slide));
    // }
}

export default comment_slider;
