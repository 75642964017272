import Hammer from '@egjs/hammerjs';
import propagating from 'propagating-hammerjs';
import Velocity from 'velocity-animate';
import debounce from 'lodash/debounce';

import step_next from './animation/step_next';
import {step_prev}from './animation/step_prev';


let project_container,
    list,
    list_projects,
    list_len,
    container_width,
    item_width,
    list_width,
    offset;


let limit = {
    start: 0,
    end: 0,
}


function animate(value) {
    list.velocity({
        transform: [`translateX(${value}px)`, `translateX(${offset}px)`]
    }, {
        duration: 500,
        complete: () => {
            setTimeout(() => {
                offset = value;
                window.ANI.accept = true;
            }, 1000);
        }
    });
}

function scroll_prev() {
    let value = offset + container_width;
    if (value > limit.start) {
        value = limit.start;
    }
    animate(value);
}

function scroll_next() {
    let value = offset - container_width;
    if (value < limit.end) {
        value = limit.end;
    }
    animate(value);
}

function getTranslateX(node) {
    var style = window.getComputedStyle(node);
    var matrix = new WebKitCSSMatrix(style.transform);
    return parseInt(matrix.m41, 10);
  }

function clearBody() {
    if(document.body.classList.contains('section-projects-show-info')){
        document.body.classList.remove('section-projects-show-info');
    }
    return true;
}

function router(direction) {
    offset = getTranslateX(list);
    if (direction === 'down') {
        if (offset > parseInt(limit.end)) {
            scroll_next();
        } else {
            // setTimeout(() => clearBody(), 3000);
            setTimeout(() => clearBody(), 1e3);
            step_next(window.event, true, true);
        }
    } if (direction === 'up') {
        if (offset < limit.start) {
            scroll_prev();
        } else {
            // setTimeout(() => clearBody(), 2000);
            setTimeout(() => clearBody(), 1e3);
            step_prev(window.event, true);
        }
    }
}


// mobile info
function mobile_info() {
    const self = this;
    const parent = self.closest('.project-item');
    const wrap = parent.querySelector('.project-small-data');
    let height = 0;

    if (parent.classList.contains('project-mobile-show')) {
        parent.classList.remove('project-mobile-show');
    } else {
        parent.classList.add('project-mobile-show');
        height = parent.querySelector('.project-small-crop').offsetHeight;
    }

    wrap.velocity({
        height: `${height}px`
    }, { duration: 250 });
}

// mobile scroll
let scroll_project_Y0 = 0;

function getSectionEnd() {
    return parseInt(project_container.children[0].offsetHeight - project_container.scrollTop) <= parseInt(project_container.offsetHeight);
}


let timeout;
function debounce_scroll(e) {
    let scroll_project_Y1 = parseInt(project_container.scrollTop);

    if (timeout) {
        clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
        if (scroll_project_Y1 == 0) {
            step_prev(e, true);
        } else if (getSectionEnd()) {
            step_next(e, true);
        }
    }, 500);

    scroll_project_Y0 = scroll_project_Y1;
};


function goto_start() {
    // let container = document.querySelector('.projects-list-container');
    // if (document.documentElement.clientWidth < 1024 && container) {
    //     container.removeEventListener('scroll', project_mobile_scroll);
    //     container.scrollTop = '0px';
    //     container.addEventListener('scroll', project_mobile_scroll);
    // }
}

let project_mobile_scroll = debounce(debounce_scroll, 200);

function projects() {
    project_container = document.querySelector('.projects-list-container');
    list = document.querySelector('.projects-list');

    if (!list) {
        return;
    }

    list_projects = list.querySelectorAll('.projects-li');

    if (document.documentElement.clientWidth < 1024) {
        document.querySelectorAll('.project-title-wrap').forEach((node) => node.addEventListener('click', mobile_info));
        let top_scroll = 0;
        let point0, point1;
        let prev_event;

        if (project_container) {

            project_container.addEventListener('touchstart', (event) => {
                point0 = event.changedTouches[0];
            });

            project_container.addEventListener('touchend', (event) => {
                point1 = event.changedTouches[0];
                let deltaY = point1.clientY - point0.clientY;
                let deltaX = point1.clientX - point0.clientX;
                let direction = deltaY < 0 ? 'down' : 'up';

                if (Math.abs(deltaY) > 20) {
                    if (direction === 'up' && parseInt(project_container.scrollTop, 10) <= 0 && prev_event === direction) {
                        step_prev(event);
                    } else if (direction === 'down' && getSectionEnd() && prev_event === direction){
                        step_next(event, true);
                    }
                    prev_event = direction;
                }
            });
        }
        return;
    }

    // Big version
    list_len = list_projects.length;

    container_width = list.parentNode.offsetWidth;
    item_width = container_width / 3;
    list_width = item_width * list_len;

    offset = 0;
    limit = {
        start: 0,
        end: -(list_width - container_width),
    }

    list.style.width = `${list_width}px`;

    // INFO
    const lang = document.documentElement.lang || 'en';
    const infobox = document.querySelector('.section-project-info');
    const title = infobox.querySelector('.h3');
    const text = infobox.querySelector('.project-information-text');
    const link = infobox.querySelector('.project-information-link a');
    const desc = infobox.querySelector('.form-desc p');

    function show_project_data() {
        const self = this;
        const id = parseInt(self.dataset.id, 10);
        const item = window.projects.find((x) => { return x.id === id });

        if (item) {
            title.innerHTML = `<a href="${item.link}" target="_blank">` + item[`name_${lang}`] + '</a>';
            text.innerHTML = item[`text_${lang}`];
            link.href = item.link;
            link.innerHTML = item.link;

            document.body.classList.add('section-projects-show-info');
        }
    }

    list_projects.forEach((node) => node.addEventListener('click', show_project_data));
}


export {
    projects,
    router,
    goto_start,
};
