import Velocity from 'velocity-animate';
import defer from 'lodash/defer';
import throttle from 'lodash/throttle';


import { unlock, replace_body_state, reset_animation_section } from './utils';
import zeroOut_oneIn from './zeroOut_oneIn';
import oneOut_twoIn from './oneOut_twoIn';
import { router } from '../projects.js';

import { check_wheel, scroll } from './index';
import mainSquare from "./mainSquare";

function next_sub_section(ani) {

    switch(ani.idx.subsection) {
        case 0:
            zeroOut_oneIn();
            window.ANI.first_in_zero = false;
            break;
        case 1:
            oneOut_twoIn();
            window.ANI.first_in_zero = false;
            break;
        default:
            unlock();
            break;
    }

    // // TODO: restore animation
    // ani.el.promo.velocity({
    //     transform: [
    //         `translateY(-${next_index * 100}%)`,
    //         `translateY(-${ani.idx.subsection * 100}%)`,
    //     ]
    // }, {
    //     duration: 1e3,
    //     complete: () => {
    //         ani.idx.subsection = next_index;
    //         replace_body_state();
    //         unlock();
    //     },
    // });
}

function hideTextLastSubsection() {
    let nodes = window.ANI.el;
    let properties = {
     opacity: [0, 1],
     transform: ['translateY(100%)', 'translateY(0)'],
    };
    let duration = 300;
    let collection = Array.from(nodes.fa21).reverse();
    let len = collection.length - 1;

    collection.forEach((node, index) => {
     if (index < len) {
         node.velocity(properties, {
             duration: duration,
             delay: duration * index,
         });
     } else {
         node.velocity(properties, {
             duration: duration,
             delay: duration * index,
      });
     }
  });
}


export function next_section(ani, status=false) {
    let next_index = ani.idx.section + 1;

    if (next_index > ani.max.section) {
        unlock();
        return;
    }

    function animateSection() {

        ani.el.box.velocity({
            transform: [
                `translateY(-${next_index * 100}%)`,
                `translateY(-${ani.idx.section * 100}%)`,
            ]
        }, {
            duration: 1e3,
            complete: () => {
                if (window.ANI.max.section === next_index) {
                    reset_animation_section();
                    setTimeout(()=> {
                        next_index = 0;
                        ani.idx.subsection = 0;
                        window.ANI.el.box.style.transform = 'translateY(0%)'
                        ani.idx.section = next_index;
                        replace_body_state();
                        unlock();
                    }, 100);
                } else {
                    ani.idx.section = next_index;
                    replace_body_state();
                    unlock();
                }

            },
        });
    }


    if(next_index < 3) {
        if(ani.idx.subsection === 2 ) {
           hideTextLastSubsection();
        }
        !status ? mainSquare('rotate', animateSection) : animateSection();
    } else {
        window.ANI.el.fa03.velocity({overflow: 'hidden'});
        window.ANI.el.fa03.style.backgroundColor = ``;
        animateSection();
        mainSquare('base', null, true);
    }
}


function step_next(e, force=false, status=false, debug=false) {

    if (debug) {
        debugger;
    }

    window.SYS.event = e.type;
    const ani = window.ANI;

    if (!ani.accept && !force) {
        let evt = e || window.event;
        evt.preventDefault();
        return false;
    }
    ani.accept = false;

    if (!force && window.ANI.current_section.dataset.el === 'project') {
        router('down');
    } /* else if (window.ANI.current_section.dataset.el === 'team') {
        if (global.TEAM.isEnd) {
            next_section(ani, true);
        }
        // else {
        //     ani.accept = true;
        // }
    } */ else if (ani.idx.section != 0) {
        next_section(ani, status);
    } else {
        if (ani.idx.subsection == ani.max.subsection) {
            next_section(ani);
        } else {
            next_sub_section(ani);
        }
    }
}

export default step_next;
