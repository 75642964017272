import axios from 'axios';
const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function make_html(data) {
    let output = '';
    data.forEach((x) => {
        output += `<li class="news-item">
        <a href="${x.link}" class="news-item-i">
            <img src="${x.thumbnail}" alt="${x.name}" class="news-item-i__pic">
        </a>
        <div class="news-item-data">
            <div>
            <div class="news-item-data__date">${x.date}</div>
            <h4 class="news-item-data__title"><a href="${x.link}">${x.name}</a></h4>
            </div>
            <div class="news-item-data-route"><a href="${x.link}"></a></div>
        </div>
        </li>`;
    });
    return output;
}

const get_position = (node) => {
    // return node.getBoundingClientRect().y + window.pageYOffset;
    return node.getBoundingClientRect().y + document.body.scrollTop;
};

function get_news(event) {
    event.preventDefault();
    const self = this;
    self.disabled = true;
    const box = self.closest('.load-container');
    const list = document.querySelector('#news-list');
    const position = get_position(self);

    axios.get(self.dataset.href)
        .then((response) => {
            const html = make_html(response.data.list);
            list.insertAdjacentHTML('beforeend', html);

            document.body.scrollTo({
                top: position,
                behavior: "smooth"
            });

            if (response.data.last) {
                box.remove();
            } else {
                self.disabled = false;
            }
        }).catch((error) => {
            console.error(error);
        });
}



const init_news = () => {
    const btn = document.querySelector('.load-btn');

    if (btn) {
        btn.addEventListener('click', get_news);
    }
};

export default init_news;
