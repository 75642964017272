// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

require("turbolinks").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import debounce from 'lodash/debounce';

// import about from './about';
import { animation } from './animation';
import { autoscrollbox } from './autoscrollbox';
import { projects } from './projects';
import team from './team';
import nav from './nav';
import mouse_light from './animation/mouse_light';
import menu_light from "./animation/menu-light";
import mainSquare from "./animation/mainSquare";
import {initBackground} from "./animation/background";


import comment_slider from './comments';

import forms_button from './forms_button';
import form_investors from './forms/investors';
import form_startups from './forms/startups';

import init_news from './get_news';

import { unlock, replace_body_state, walk_animation_section } from './animation/utils';



const simplerouter = () => {
    if(!document.getElementById('pagepiling')) {
        return;
    }

    const path = window.location.pathname.replace(/^\/(uk|ru)\//, '/').substring(1).split('/')[0];
    const nav = Array.from(document.querySelectorAll('.first-nav a'));
    let node = nav.find((x) => { return x.dataset.isAnchor === 'True' && x.dataset.target === path });

    if (!node) {
        return;
    }

    const target = document.querySelector(`*[data-el="${node.dataset.target}"]`);
    let next_index = parseInt(target.dataset.sectionIndex, 10);

    walk_animation_section();

    window.ANI.el.box.velocity({
        transform: [
            `translateY(-${next_index * 100}%)`,
            `translateY(-${window.ANI.idx.section * 100}%)`,
        ]
    }, {
        duration: 100,
        complete: () => {
            window.ANI.idx.section = next_index;
            replace_body_state();
            unlock();
            document.documentElement.classList.remove('show-preloader');
            // holder.classList.remove('animate');
        },
    });
    // if (node) {
    //     let eventClick = new Event("click");
    //     node.dispatchEvent(eventClick);
    // }
};



// document.addEventListener('DOMContentLoaded', () => {
document.addEventListener('turbolinks:load', () => {

    window.SYS = {};
    window.SYS.width = document.documentElement.clientWidth;
    window.SYS.height = document.documentElement.clientHeight;
    window.SYS.aspect = window.SYS.height / window.SYS.width;
    window.SYS.dia = Math.sqrt(Math.pow(window.SYS.height, 2) + Math.pow(window.SYS.width, 2));
    window.SYS.event = null;

    window.ANI = {
        first_in_zero: true,
        accept: true,
        ready: true,
        el: {
            body: document.body,
            box: document.querySelector('#pagepiling'),
            sections: document.querySelectorAll('#pagepiling > .section'),
            promo: document.querySelector('.animations'),
            promo_slides: document.querySelectorAll('.animation-slide'),
            fa01: document.querySelector('.fa01'),
            fa02: document.querySelector('.fa02'),
            fa03: document.querySelector('.fa03 span'),
            fa11: document.querySelector('.fa11'),
            fa12: document.querySelector('.fa12'),
            fa21: document.querySelectorAll('.fa21 span'),
            fa22: document.querySelector('.fa22'),
        },
        idx: {
            section: 0,
            subsection: 0,
        },
        max: {
            section: document.querySelectorAll('#pagepiling > .section').length - 1,
            subsection: document.querySelectorAll('.animation-slide').length - 1,
        },
        current_section: document.querySelectorAll('#pagepiling > .section')[0],
    };


    function get_variant() {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        let orientation = width / height;

        if (orientation < 1 && height >= 540) {
            return 'default';
        }

        if (orientation >= 1 && height < 540) {
            return 'landscape';
        }

        return 'default';
    }

    // about();
    team();
    nav();

    forms_button();
    form_investors();
    form_startups();

    init_news();

    menu_light();

    let isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
    if (!isTouchDevice) {
        mouse_light();
    }

    if (document.querySelector('#square')) {
        // console.log('mainSquare');
        mainSquare('base');
    }

    initBackground();

    if (window.ANI.el.box) {
        animation();
    }

    projects();
    comment_slider();
    // autoscrollbox();

    let variant = get_variant();
    if (variant === 'landscape') {
        window.ANI.el.body.classList.add('landscape-mobile');
    }

    window.addEventListener('resize', debounce(function(){
        variant = get_variant();

        if (variant === 'landscape') {
            window.ANI.el.body.classList.add('landscape-mobile');
        } else {
            window.ANI.el.body.classList.remove('landscape-mobile');
        }

        window.SYS.width = document.documentElement.clientWidth;
        window.SYS.height = document.documentElement.clientHeight;
        window.SYS.aspect = window.SYS.height / window.SYS.width;
        window.SYS.dia = Math.sqrt(Math.pow(window.SYS.height, 2) + Math.pow(window.SYS.width, 2));

    }, 150));

    simplerouter();
});

document.addEventListener('turbolinks:click', () => {
    document.documentElement.classList.add('show-preloader');
});

document.addEventListener('turbolinks:load', () => {
    setTimeout(() => {
        document.documentElement.classList.remove('show-preloader');
    }, 250);

});


