import Velocity from 'velocity-animate';

import { unlock, replace_body_state } from './utils';
import oneOut_zeroIn from './oneOut_zeroIn';
import twoOut_oneIn from './twoOut_oneIn';
import { router } from '../projects.js';
import mainSquare from "./mainSquare";


function prev_sub_section(ani) {

    switch(ani.idx.subsection) {
        case 1:
            oneOut_zeroIn();
            window.ANI.first_in_zero = true;
            break;
        case 2:
            twoOut_oneIn();
            window.ANI.first_in_zero = false;
            break;
        default:
            unlock();
            break;
    }

    // let next_index = ani.idx.subsection - 1;

    // if (next_index < 0) {
    //     unlock();
    //     return;
    // }

    // // TODO: restore animation
    // ani.el.promo.velocity({
    //     transform: [
    //         `translateY(-${next_index * 100}%)`,
    //         `translateY(-${ani.idx.subsection * 100}%)`,
    //     ]
    // }, {
    //     duration: 1e3,
    //     complete: () => {
    //         ani.idx.subsection = next_index;
    //         replace_body_state();
    //         unlock();
    //     },
    // });
}

 function showTitlesPrevSubsection() {
    let nodes = window.ANI.el;
    let properties = {
        opacity: [1, 0],
        transform: ['translateY(0)', 'translateY(100%)'],
    };
    let duration = 300;
    let len = nodes.fa21.length - 1;
    nodes.fa21.forEach((node, index) => {
        if (index < len) {
            node.velocity(properties, {
                duration: duration,
                delay: duration * index,
            });
        } else {
            node.velocity(properties, {
                duration: duration,
                delay: duration * index,
            });
        }
    });
}

function prev_section(ani) {

    let next_index = ani.idx.section - 1;

    if (next_index < 0) {
        unlock();
        return;
    }
    let nodes = window.ANI.el;
    nodes.fa21.forEach(node => {
        node.style.opacity = '0';
        node.style.transform = 'translateY(0)';
    });
   mainSquare('moveBottom');

  /*  nodes.promo_slides[1].style.top = '200%';*/
    nodes.fa03.style.opacity = 1;
    if (ani.idx.section === 1) {
      setTimeout(() => showTitlesPrevSubsection(), 500);
    }

    ani.el.box.velocity({
        transform: [
            `translateY(-${next_index * 100}%)`,
            `translateY(-${ani.idx.section * 100}%)`,
        ]
    }, {
        duration: 1e3,
        complete: () => {
            setTimeout(() => {
                ani.idx.section = next_index;
                if (ani.idx.section == 1) {
                    window.ANI.project_active = true;
                }
                replace_body_state();
                unlock();
            }, 100);
        },
    });
}

let teamTimeout;

function step_prev(e, force=false) {

    window.SYS.event = e.type;
    const ani = window.ANI;

    if (!ani.accept && !force) {
        let evt = e || window.event;
        evt.preventDefault();
        return false;
    }
    ani.accept = false;

    if (!force && window.ANI.current_section.dataset.el === 'project') {
        router('up');
    } /* else if (window.ANI.current_section.dataset.el === 'team') {
        if (global.TEAM.isStart) {
            prev_section(ani);
        } else {
            ani.accept = true;
        }
    } */ else if (ani.idx.section != 0) {
        prev_section(ani);
    } else {
        prev_sub_section(ani);
    }
}

export {
    showTitlesPrevSubsection,
    step_prev
}
