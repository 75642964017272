import { unlock, replace_body_state } from './utils';

let zeroOut_oneIn = () => {
    let nodes = window.ANI.el;
    let curernt_index = window.ANI.idx.subsection;
    let next_index = curernt_index + 1;

    let hideAnim = new Promise((resolve, reject) => {

        nodes.fa01
            .velocity({ transform: ['translateY(300%)', 'translateY(0%)'] }, { duration: 500 })
            .velocity({ opacity: 0 }, { duration: 250 });

        nodes.fa02
            .velocity({ transform: ['translateY(-300%)', 'translateY(0%)'] }, { duration: 500 })
            .velocity({ opacity: 0 }, { duration: 250 });

        nodes.fa03
            .velocity({ transform: ['translate(-50%, -50%) rotate(-45deg)', 'translate(-50%, -50%) rotate(0deg)'] }, { duration: 500 })
            .velocity({ width: `${window.SYS.dia}px` }, { duration: 500 })
            .velocity({ height: `${window.SYS.dia}px` }, {
                duration: 500,
                complete: function() {
                    resolve();
                }
            })
            .velocity({overflow: 'visible'})


    });


    hideAnim.then(() => {
        nodes.promo.style.transform = `translateY(-${next_index * 100}%)`;
        nodes.fa11
            .velocity({ transform: ['translateX(0px)', 'translateX(100vw)'] }, {
                duration: 2000,
                easing: [0,1.73,.76,.95] // https://cubic-bezier.com/
            });
        nodes.fa12
            .velocity({ transform: ['translateX(0px)', 'translateX(-100vw)'] }, {
                duration: 2000,
                // easing: [0,1.58,.2,.9],
                easing: [0,1.73,.76,.95],
                complete: () => {
                    window.ANI.idx.subsection = next_index;
                    replace_body_state();
                    unlock();
                },
            });
    });
}

export default zeroOut_oneIn;
