import { step_prev } from './animation/step_prev';
import step_next from './animation/step_next';


let prevTime = new Date().getTime();
let scrollings = [];


function getSectionEnd(element) {
    //*
    return parseInt(element.children[0].offsetHeight - element.scrollTop) <= parseInt(element.offsetHeight);
    return parseInt(element.querySelector('.slide').offsetHeight - element.scrollTop) <= parseInt(element.offsetHeight);

    /*/
    // new variant
    let container = element.querySelector('.autoheight-slide');
    return parseInt(element.querySelector('.partners-container').offsetHeight - container.scrollTop) <= parseInt(container.offsetHeight);
	//*/
}


function getSectionStart(element) {
	//*
    return parseInt(element.scrollTop ,10) <= 0;
    /*/
    // new variant
    return parseInt(element.querySelector('.autoheight-slide').scrollTop ,10) <= 0;
    * //*/
}


function getAverage(elements, number){
    let sum = 0;
    // taking `number` elements from the end to make the average, if there are not enought, 1
    let lastElements = elements.slice(Math.max(elements.length - number, 1));
    for(let i = 0; i < lastElements.length; i++){
        sum = sum + lastElements[i];
    }
    return Math.ceil(sum/number);
}

function getEventTarget(event) {
    let target = event.target.classList.contains('section') ? event.target : event.target.closest('.section');
    return target;
}

function check_position(event) {
    if (!event.currentTarget) {
        return false;
    }

    let result = {
        isStart: false,
        isEnd: false,
    };

    let self = event.currentTarget;

    // if (parseInt(self.scrollTop, 10) <= 0) {
    //     result.isStart = true;
    // }

    result.isStart = getSectionStart(self);
    result.isEnd = getSectionEnd(self);
    return result;
}


function MouseWheelHandler(e) {
    e.stopPropagation();
    let curTime = new Date().getTime();

    // cross-browser wheel delta
    e = e || window.event;
    let value = e.wheelDelta || -e.deltaY || -e.detail;
    let delta = Math.max(-1, Math.min(1, value));

    let isScrollingVertically = (Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta)) || (Math.abs(e.deltaX ) < Math.abs(e.deltaY) || !horizontalDetection);

    // Limiting the array to 150 (lets not waste memory!)
    if(scrollings.length > 149){
        scrollings.shift();
    }

    // keeping record of the previous scrollings
    scrollings.push(Math.abs(value));

    // time difference between the last scroll and the current one
    var timeDiff = curTime-prevTime;
    prevTime = curTime;

    // haven't they scrolled in a while?
    // (enough to be consider a different scrolling action to scroll another section)
    if(timeDiff > 200){
        // emptying the array, we dont care about old scrollings for our averages
        scrollings = [];
    }

    // console.log(`index accept: ${window.ANI.accept}`);
    let valid_section = getEventTarget(e) === window.ANI.current_section;
    if(window.ANI.accept && valid_section){
        let averageEnd = getAverage(scrollings, 10);
        let averageMiddle = getAverage(scrollings, 70);
        let isAccelerating = averageEnd >= averageMiddle;

        // to avoid double swipes...
        if(isAccelerating && isScrollingVertically){
            let pos_node = check_position(e);
            // scrolling down?
            if (delta < 0 && pos_node.isEnd) {
                step_next(e);
            // scrolling up?
            } else if (delta > 0 && pos_node.isStart) {
                step_prev(e);
            }
        }
    } else {
        e.preventDefault();
    }
    return false;
}


//////////////// MOBILE
let point0 = 0;
let point1 = 0;
let prev_event;

function autoscrollbox() {

    if ('ontouchstart' in document.documentElement) {
        // document.querySelectorAll('.section-auto').forEach((node) => {
        document.querySelectorAll('.section-auto').forEach((node) => {
            node.addEventListener('touchstart', (event) => {
                point0 = event.changedTouches[0];
            });

            node.addEventListener('touchend', (event) => {
                let self = event.currentTarget;

                point1 = event.changedTouches[0];
                let deltaY = point1.clientY - point0.clientY;
                let deltaX = point1.clientX - point0.clientX;
                let direction = deltaY < 0 ? 'down' : 'up';

                if (Math.abs(deltaY) > 20) {
                    let pos_node = check_position(event);

                    // if (direction === 'up' && pos_node.isStart && prev_event === direction) {
                    if (direction === 'up' && pos_node.isStart) {
                        step_prev(event);
                    // } else if (direction === 'down' && pos_node.isEnd && prev_event === direction){
                    } else if (direction === 'down' && pos_node.isEnd){
                        step_next(event);
                    }
                    prev_event = direction;
                }
            });
        });


    } else {
        document.querySelectorAll('.section-auto').forEach((node) => {
            node.addEventListener('wheel', MouseWheelHandler, {passive: false} );
        });
    }
}

export {
    autoscrollbox,
}
