function forms_buttons() {

    function openForms() {
        document.body.classList.add(`show-${this.dataset.target}-form`);
    }

    function closeAllPopup() {
        document.body.classList.remove(
            'show-investors-form',
            'show-startups-form',
            'section-projects-show-info',
        )
    }

    document.querySelectorAll('.first-word').forEach((x) => x.addEventListener('click', openForms));
    document.querySelectorAll('.form-close').forEach((x) => x.addEventListener('click', closeAllPopup));
}

export default forms_buttons;
