import Velocity from 'velocity-animate';
import { unlock, replace_body_state, reset_animation_section, walk_animation_section } from './animation/utils';
import mainSquare from "./animation/mainSquare";
import {background} from "./animation/background";

let nav_node;
let menu_text = document.querySelectorAll('.menu-text');



function refresh(event) {

    if (window.ANI.idx.section == 0 && window.ANI.idx.subsection == 0 && !window.ANI.el.body.classList.contains('view-about')) {
        return;
    }

    window.ANI.accept = false;
    let nodes = window.ANI.el;
    // const holder = document.querySelector('.link-holder');
    // const cube = holder.querySelector('div');

    event.preventDefault();
    nodes.fa03.velocity({overflow: 'hidden'});
    window.ANI.el.fa03.style.backgroundColor = ``;
    // cube.style.left = `${event.clientX}px`;
    // cube.style.top = `${event.clientY}px`;
    // holder.classList.add('animate');

    setTimeout(() => {
        window.ANI.el.body.classList.remove('view-about');
        let next_index = 0;
        nav_node.velocity({
            transform: ['translateX(100%)', 'translateX(0%)']
        }, { duration: 100 });

        reset_animation_section();
        mainSquare('base', null, true);
        window.ANI.el.box.velocity({
            transform: [
                `translateY(-${next_index * 100}%)`,
                `translateY(-${window.ANI.idx.section * 100}%)`,
            ]
        }, {
            duration: 100,
            complete: () => {
                window.ANI.idx.section = next_index;
                replace_body_state();
                unlock();
                // holder.classList.remove('animate');
                background('about');
                if(document.body.classList.contains('section-projects-show-info')){
                    document.body.classList.remove('section-projects-show-info');
                }
            },
        });
    }, 550);
}



const make_url = (url) => {
    // const lang = document.documentElement.lang;
    // console.log(lang);
    // const prefix = lang == 'en' ? '' : `/${lang}`;
    // return `${prefix}${url}`;
    const prefix = document.querySelector('#pagepiling').dataset.langlink;
    return `${prefix}/${url}`;
}


function nav_event(event) {

    // console.log(`window.isMain: ${window.isMain}`);

    // if (window.isMain) {
        window.ANI.accept = false;
        let self = event.target;

        if (self.dataset.isAnchor === 'False' || !document.querySelector('#pagepiling')) {
            return;
        }

        event.preventDefault();
        document.documentElement.classList.add('show-preloader');

        // const holder = document.querySelector('.link-holder');
        // const cube = holder.querySelector('div');
        // event.preventDefault();
        // cube.style.left = `${event.clientX}px`;
        // cube.style.top = `${event.clientY}px`;
        // holder.classList.add('animate');

        setTimeout(() => {
            let target = self.dataset.target;

            // if (target === 'about') {
            //     // window.ANI.el.body.classList.add('view-about');
            //     // try {
            //     //     let bgCanvas = document.querySelector('canvas#canvas-about');
            //     //     let bgHeight = document.querySelector('.about-content').offsetHeight;
            //     //     // bgCanvas.height = bgHeight;
            //     //     bgCanvas.style.height = `${bgHeight}px`;
            //     // } catch(e) {
            //     //     // empty
            //     // }

            //     // nav_node.velocity({
            //     //     transform: ['translateX(100%)', 'translateX(0%)']
            //     // }, {
            //     //     complete: () => {
            //     //         unlock();
            //     //         // holder.classList.remove('animate');
            //     //         history.replaceState(null, document.title, make_url('/about'));
            //     //     },
            //     // });

            // } else {
            walk_animation_section();
            // window.ANI.el.body.classList.remove('view-about');

            const newurl = make_url(target);
            if (newurl !== window.location.pathname) {
                history.replaceState(null, document.title, newurl);
            }

            target = document.querySelector(`*[data-el="${target}"]`);
            let next_index = parseInt(target.dataset.sectionIndex, 10);

            // console.log(make_url(target));

            nav_node.velocity({
                transform: ['translateX(100%)', 'translateX(0%)']
            }, { duration: 100 });
            window.ANI.el.box.velocity({
                transform: [
                    `translateY(-${next_index * 100}%)`,
                    `translateY(-${window.ANI.idx.section * 100}%)`,
                ]
            }, {
                duration: 100,
                complete: () => {
                    window.ANI.idx.section = next_index;
                    replace_body_state();
                    unlock();
                    document.documentElement.classList.remove('show-preloader');
                    // holder.classList.remove('animate');
                },
            });
            // }
        }, 550);
    // }
}



function nav() {
    const btn_open = document.querySelector('.top-plus');
    const btn_close = document.querySelector('.close-nav');
    nav_node = document.querySelector('.global-nav-box');

    btn_open.addEventListener('click', () => {
        nav_node
            .velocity({
            transform: ['translateX(0%)', 'translateX(100%)']
        }, {
            duration: 10,
        })
            .velocity({opacity: 1}, { duration: 500 })
    });
    document.querySelectorAll('*[data-action="nav"]').forEach((x) => x.addEventListener('click', nav_event));
    // document.querySelector('.logo').addEventListener('click', refresh);
}

export default nav;
