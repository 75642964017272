import * as THREE from "three";
import {
    EffectComposer,
    EffectPass,
    RenderPass,
    SavePass,
    BlendFunction,
    NoiseEffect,
} from "postprocessing";

function background(type, noise=0.25) {
    let activeSelector;
    switch (type) {
        case 'main':
            activeSelector = '#canvas-fon';
            break;
        case 'partners':
            activeSelector = '#canvas-gray';
            break;
        case 'menu':
            activeSelector = '#fon';
            break;
        case 'form':
            activeSelector = '#canvas-form';
            break;
        case 'startup':
            activeSelector = '#canvas-startup';
            break;
        case 'about':
            activeSelector = '#canvas-about';
            break;
        case 'project':
            activeSelector = '#project';
            break;
    }

    const canvas = document.querySelector(activeSelector);

    if (!canvas) {
        return;
    }

    if (type === 'about') {
        let sizeWidth = document.querySelector('.about-slide-1').offsetWidth;
        canvas.width = sizeWidth;
        canvas.height = window.innerHeight;

    }else if(type === 'form' || type === 'startup') {
        let formHeight = document.querySelector('.form-slide_for-investors').scrollHeight;
        let formWidth = document.querySelector('.form-slide_for-investors').scrollWidth;
        canvas.width = formWidth;
        canvas.height = formHeight;
    } else if (type === 'partners'){
        let elementHeight = document.querySelector('.section-auto').scrollHeight;
        canvas.height = elementHeight;
        canvas.width = window.innerWidth;
    } else {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
    }

    const renderer = new THREE.WebGLRenderer({
        canvas,
        alpha: true,
    });
    let activeColor = type !== 'partners'? '#262626' : '#AFAFAF';
    if(type === 'about') activeColor = '#8C8C8C';
    renderer.setClearColor( activeColor, 1 );
    renderer.setSize(canvas.width, canvas.height);
    renderer.shadowMap.enabled = true;

    const scene = new THREE.Scene();
    let camera = new THREE.OrthographicCamera(canvas.width / -1.5, canvas.width / 1.5, canvas.height / 1.5, canvas.height / -1.5, 1, 1000);

    const color = 0xFFFFFF;
    scene.add(camera);

    renderer.render(scene, camera);
    renderer.shadowMap.enabled = true;

    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    const savePass = new SavePass();

    const noiseEffect = new NoiseEffect({
        blendFunction: BlendFunction.SOFT_LIGHT,
    });

    noiseEffect.blendMode.opacity.value = noise;
    const effectPass = new EffectPass(camera, noiseEffect);

    composer.addPass(savePass);
    composer.addPass(effectPass);

    composer.setSize(canvas.width, canvas.height);
    composer.render();
}

function initBackground() {
   // let types = ['main', 'partners', 'form', 'startup', 'project', 'about'];
   let types = ['main', 'form', 'startup', 'project', 'about'];
   types.forEach(item => background(item));
}

export {
    background,
    initBackground
};
