import { check_wheel, scroll } from './index';
import { goto_start } from '../projects';

const url_map = [
    '/',
    '/project',
    '/team',
    '/partners'
];

const make_url = (index) => {
    const lang = document.documentElement.lang;
    const prefix = lang == 'en' ? '' : `/${lang}`;
    return `${prefix}${url_map[index]}`;
}


function unlock() {
    let delay = window.SYS.event === 'wheel' ? 500 : 4;
    setTimeout(() => {
        window.ANI.current_section = window.ANI.el.sections[window.ANI.idx.section];
        window.ANI.accept = true;
    }, delay);
    // window.addEventListener('mousewheel', scroll, { passive: false });
}

function replace_body_state() {
    window.ANI.el.body.className = window.ANI.el.body.className.replace(/\bviewing-section-\d.*?\b/g, '');
    window.ANI.el.body.className = window.ANI.el.body.className.replace(/\bviewing-sub-section-\d.*?\b/g, '');
    window.ANI.el.body.classList.add(`viewing-section-${window.ANI.idx.section}`);
    window.ANI.el.body.classList.add(`viewing-sub-section-${window.ANI.idx.subsection}`);

    // const newURL = make_url(window.ANI.idx.section);
    // if (window.location.pathname !== newURL) {
    //     history.replaceState(null, document.title, make_url(window.ANI.idx.section));
    // }
}

function reset_animation_section() {
    let nodes = window.ANI.el;

    // slide 0
    nodes.fa01.velocity({
        opacity: [1, 0],
        transform: ['translateY(0%)', 'translateY(300%)'],
    }, { duration: 0.1 });
    nodes.fa02.velocity({
        opacity: [1, 0],
        transform: ['translateY(0%)', 'translateY(300%)'],
    }, { duration: 0.1 });
    nodes.fa03.velocity({
        height: ['2px', `${window.SYS.dia}px`],
        width: ['100%', `${window.SYS.dia}px`],
        opacity: [1, 0],
        transform: ['translate(-50%, -50%) rotate(0deg)', 'translate(-50%, -50%) rotate(-45deg)'],
    }, { duration: 0.1 });

    // slide 1
    nodes.fa11.velocity({
        transform: ['translateX(100vw)', 'translateX(0px)'],
        opacity: [1, 0],
    }, { duration: 0.1 });
    nodes.fa12.velocity({
        transform: ['translateX(100vw)', 'translateX(0px)'],
        opacity: [1, 0],
    }, { duration: 0.1 });

    // slide 2
    nodes.fa21.forEach((node, index) => {
        node.velocity({
            opacity: [0, 1],
            transform: ['translateY(100%)', 'translateY(0)'],
        }, { duration: 0.1 });
    });
    nodes.fa22.velocity({ opacity: [0, 1] }, {
        duration: 0.1,
        complete: () => {
            window.ANI.first_in_zero = true;
            window.ANI.el.promo.style.transform = `translateY(0%)`;
            window.ANI.idx.subsection = 0;
            // document.querySelector('.projects-list-container').scrollTop = '1px';
        }
    });

    document.querySelector('.projects-list').velocity({
        transform: ['translateX(0px)', 'translateX(-100%)']
    }, { duration: 0.1 });

    // goto_start();
    // document.querySelector('.projects-list-container').style.overflow = 'hidden';
    // document.querySelector('.projects-list-container').scrollTop = 0;
    // document.querySelector('.projects-list-container').style.overflow = 'auto';
}


function walk_animation_section() {
    let nodes = window.ANI.el;

    // slide 0
    nodes.fa01.velocity({
        opacity: [0, 1],
        transform: ['translateY(300%)', 'translateY(0%)'],
    }, { duration: 0.1 });
    nodes.fa02.velocity({
        opacity: [0, 1],
        transform: ['translateY(300%)', 'translateY(0%)'],
    }, { duration: 0.1 });
    nodes.fa03.velocity({
        height: [`${window.SYS.dia}px`, '2px'],
        width: [`${window.SYS.dia}px`, '100%'],
        opacity: [0, 1],
        transform: ['translate(-50%, -50%) rotate(-45deg)', 'translate(-50%, -50%) rotate(0deg)'],
    }, { duration: 0.1 });

    // slide 1
    nodes.fa11.velocity({
        transform: ['translateX(-100vw)', 'translateX(0)'],
        opacity: [0, 1],
    }, { duration: 0.1 });
    nodes.fa12.velocity({
        transform: ['translateX(100vw)', 'translateX(0)'],
        opacity: [0, 1],
    }, { duration: 0.1 });

    // slide 2
    nodes.fa21.forEach((node, index) => {
        node.velocity({
            opacity: [1, 0],
            transform: ['translateY(0)', 'translateY(100%)'],
        }, { duration: 0.1 });
    });
    nodes.fa22.velocity({ opacity: [1, 0] }, {
        duration: 0.1,
        complete: () => {
            window.ANI.el.promo.style.transform = `translateY(-${window.ANI.max.subsection * 100}%)`;
            window.ANI.idx.subsection = window.ANI.max.subsection;
        }
    });

}



export {
    unlock,
    replace_body_state,
    reset_animation_section,
    walk_animation_section,
};
