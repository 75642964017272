import { unlock, replace_body_state } from './utils';

let oneOut_zeroIn = () => {

    let nodes = window.ANI.el;
    let curernt_index = window.ANI.idx.subsection;
    let next_index = curernt_index - 1;

    let hideAnim = new Promise((resolve, reject) => {
        nodes.fa11
            .velocity({ transform: ['translateX(100vw)', 'translateX(0px)']}, {
                duration: 1000,
            });
        nodes.fa12
            .velocity({ transform: ['translateX(-100vw)', 'translateX(0px)'] }, {
                duration: 1000,
                complete: function() {
                    resolve();
                },
            });
        nodes.fa03
         .velocity({overflow: 'hidden'})
    });

    hideAnim.then(() => {
        nodes.promo.style.transform = `translateY(-${next_index * 100}%)`;

        nodes.fa03
            .velocity({
                    height: ['2px', `${window.SYS.dia}px`]
                }, { duration: 500 })
            .velocity({
                width: ['100%', `${window.SYS.dia}px`]
                }, {
                    duration: 500,
                    complete: function() {
                        nodes.fa01
                            .velocity({ opacity: [1, 0] }, { duration: 250 })
                            .velocity({ transform: ['translateY(0%)', 'translateY(300%)'] }, { duration: 250 });


                        nodes.fa02
                            .velocity({ opacity: [1, 0] }, { duration: 250 })
                            .velocity({ transform: ['translateY(0%)', 'translateY(-300%)'] }, {
                                duration: 250,
                            });
                    }
                })

        .velocity({ transform: [
                    'translate(-50%, -50%) rotate(0deg)',
                    'translate(-50%, -50%) rotate(-45deg)'
                    ]}, {
                        duration: 500,
                        complete: function() {
                            window.ANI.idx.subsection = next_index;
                            replace_body_state();
                            unlock();
                        },
                });
    });
};

export default oneOut_zeroIn;
