import Hammer from '@egjs/hammerjs';
import propagating from 'propagating-hammerjs';
import debounce from 'lodash/debounce';
// import throttle from 'lodash/throttle';
// import defer from 'lodash/defer';
// import once from 'lodash/once';

import {step_prev} from './step_prev';
import step_next from './step_next';
import { replace_body_state } from './utils';

/**
 * Detecting mousewheel scrolling
 *
 * http://blogs.sitepointstatic.com/examples/tech/mouse-wheel/index.html
 * http://www.sitepoint.com/html5-javascript-mouse-wheel/
 */

let prevTime = new Date().getTime();
let scrollings = [];


function getAverage(elements, number){
    let sum = 0;
    // taking `number` elements from the end to make the average, if there are not enought, 1
    let lastElements = elements.slice(Math.max(elements.length - number, 1));
    for(let i = 0; i < lastElements.length; i++){
        sum = sum + lastElements[i];
    }
    return Math.ceil(sum/number);
}

function getEventTarget(event) {
    let target = event.target.classList.contains('section') ? event.target : event.target.closest('.section');
    return target;
}


function MouseWheelHandler(e) {
    let curTime = new Date().getTime();

    // cross-browser wheel delta
    e = e || window.event;
    let value = e.wheelDelta || -e.deltaY || -e.detail;
    let delta = Math.max(-1, Math.min(1, value));

    let isScrollingVertically = (Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta)) || (Math.abs(e.deltaX ) < Math.abs(e.deltaY) || !horizontalDetection);

    // Limiting the array to 150 (lets not waste memory!)
    if(scrollings.length > 149){
        scrollings.shift();
    }

    // keeping record of the previous scrollings
    scrollings.push(Math.abs(value));

    // time difference between the last scroll and the current one
    var timeDiff = curTime-prevTime;
    prevTime = curTime;

    // haven't they scrolled in a while?
    // (enough to be consider a different scrolling action to scroll another section)
    if(timeDiff > 200){
        // emptying the array, we dont care about old scrollings for our averages
        scrollings = [];
    }

    // console.log(`index accept: ${window.ANI.accept}`);
    let valid_section = getEventTarget(e) === window.ANI.current_section;
    if(window.ANI.accept && valid_section){
        let averageEnd = getAverage(scrollings, 10);
        let averageMiddle = getAverage(scrollings, 70);
        let isAccelerating = averageEnd >= averageMiddle;

        // to avoid double swipes...
        if(isAccelerating && isScrollingVertically){
            // scrolling down?
            if (delta < 0) {
                step_next(e);
            // scrolling up?
            } else {
                step_prev(e);
            }
        }
    } else {
        e.preventDefault();
    }
    return false;
}

function animation() {

    const duration = 0;
    window.ANI.el.box.style.position = 'relative';
    // window.ANI.el.box.style.transition = `all ${duration}ms ease 0s`;

    // window.ANI.el.promo.style.position = 'relative';
    // // window.ANI.el.promo.style.transition = `all ${duration}ms ease 0s`;

    window.ANI.el.sections.forEach((node, index) => {
        node.style.position = 'absolute';
        node.style.top = `${index * 100}%`;
        node.dataset.sectionIndex = index;
    });

    window.ANI.el.promo_slides.forEach((node, index) => {
        node.style.position = 'absolute';
        node.style.top = `${index * 100}%`;
        node.dataset.aniIndex = index;
    });

    replace_body_state();

    window.ANI.el.box.addEventListener('wheel', MouseWheelHandler, { passive: false });

    const down_link = document.querySelector('.down-button');
    if (down_link) {
        down_link.addEventListener('click', step_next);
    }

    // TOUCH SECTION

    let isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
    let isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints));

    let touchStartY = 0;
    let touchStartX = 0;
    let touchEndY = 0;
    let touchEndX = 0;

    let touchSensitivity = 5;



    function preventDefault(event){
        if(event.preventDefault){
            event.preventDefault();
        }
        else{
            event.returnValue = false;
        }
    }

    function getMSPointer(){
        var pointer;

        //IE >= 11 & rest of browsers
        if(window.PointerEvent){
            pointer = { down: 'pointerdown', move: 'pointermove'};
        }

        //IE < 11
        else{
            pointer = { down: 'MSPointerDown', move: 'MSPointerMove'};
        }

        return pointer;
    }

    let MSPointer = getMSPointer();
    let events = {
        touchmove: 'ontouchmove' in window ? 'touchmove' :  MSPointer.move,
        touchstart: 'ontouchstart' in window ? 'touchstart' :  MSPointer.down
    };

    function isReallyTouch(e){
        //if is not IE   ||  IE is detecting `touch` or `pen`
        return typeof e.pointerType === 'undefined' || e.pointerType != 'mouse';
    }

    function preventBouncing(e){
        // if(options.autoScrolling && isReallyTouch(e) && isScrollAllowed.m.up){
        if(isReallyTouch(e) && isScrollAllowed.m.up){
            //preventing the easing on iOS devices
            preventDefault(e);
        }
    }

    function getEventsPage(e){
        let events = [];
        events.y = (typeof e.pageY !== 'undefined' && (e.pageY || e.pageX) ? e.pageY : e.touches[0].pageY);
        events.x = (typeof e.pageX !== 'undefined' && (e.pageY || e.pageX) ? e.pageX : e.touches[0].pageX);
        //in touch devices with scrollBar:true, e.pageY is detected, but we have to deal with touch events. #1008
        if(isTouch && isReallyTouch(e) && typeof e.touches !== 'undefined'){
            events.y = e.touches[0].pageY;
            events.x = e.touches[0].pageX;
        }
        return events;
    }

    /**
    * Handler for the touch start event.
    */
    function touchStartHandler(e){
        //stopping the auto scroll to adjust to a section
        // if(options.fitToSection){
        //     activeAnimation = false;
        // }
        if(isReallyTouch(e)){
            let touchEvents = getEventsPage(e);
            touchStartY = touchEvents.y;
            touchStartX = touchEvents.x;
        }
    }

    function touchMoveHandler(e){

        if (isReallyTouch(e) ) {

            // if (e.target.closest('.projects-section') || e.target.closest('.section-auto')) {
            if (e.target.closest('.projects-section')) {
                return;
            }

            //preventing the easing on iOS devices
            preventDefault(e);

            let touchEvents = getEventsPage(e);

            touchEndY = touchEvents.y;
            touchEndX = touchEvents.x;

            if (window.ANI.accept) {
                if (Math.abs(touchStartY - touchEndY) > (window.innerHeight / 100 * touchSensitivity)) {
                    if (touchStartY > touchEndY) {
                        // step_next(e);
                        step_next(e, true, true, false);
                    } else if (touchEndY > touchStartY) {
                        step_prev(e);
                    }
                }
            }
        }
    }


    /**
    * Removes the auto scrolling for touch devices.
    */
    function removeTouchHandler(){
        if(isTouchDevice || isTouch){
            let touchWrapper = window.ANI.el.box;
            touchWrapper.removeEventListener(events.touchstart, touchStartHandler);
            touchWrapper.removeEventListener(events.touchmove, touchMoveHandler, {passive: false});
        }
    }


    function addTouchHandler(){
        if(isTouchDevice || isTouch){
            let touchWrapper = window.ANI.el.box;
            touchWrapper.removeEventListener(events.touchstart, touchStartHandler);
            touchWrapper.removeEventListener(events.touchmove, touchMoveHandler, {passive: false});

            touchWrapper.addEventListener(events.touchstart, touchStartHandler);
            touchWrapper.addEventListener(events.touchmove, touchMoveHandler, {passive: false});
        }
    }

    addTouchHandler();

    // Keyboards
    function check_keyboard(event) {
        const tag = event.target.tagName.toLowerCase();

        if (tag === 'input' || tag === 'textarea' || document.body.classList.contains('view-about')) {
            return;
        }

        switch(event.which) {
            case 33: // page up
            case 36: // home
            case 38: // up
                if (window.ANI.accept) step_prev(event);
                break;

            case 32: // spacebar
            case 34: // page dwn
            case 35: // end
            case 40: // down
                if (window.ANI.accept) step_next(event);
                break;

            default:
                return;
        }
    }

    document.addEventListener('keydown', check_keyboard, false);

}

export {
    animation
}
