// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import debounce from 'lodash/debounce';

function get_variant() {
    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;
    let orientation = width / height;

    if (orientation < 1 && height >= 540) {
        return 'default';
    }

    if (orientation >= 1 && height < 540) {
        return 'landscape';
    }

    return 'default';
}

export default function team() {
    let breakpoints = {
        'default': {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3,
            },
        },
        'landscape': {
            540: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3,
            },
        }
    }

    let variant = breakpoints[get_variant()];

    global.teamSwiper = new Swiper('.team-swiper-container', {
        loop: false,
        // touchAngle: 90,
        allowTouchMove: false,
        // allowTouchMove: true,
        slidesPerView: 1,
        spaceBetween: 16,
        speed: 1000,
        breakpoints: variant,
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        on: {
            'transitionStart': function() {
                let box = document.querySelector('.show-user-info');
                if (box) {
                    box.classList.remove('show-user-info');
                }
            },
            'imagesReady': function() {
                if (document.documentElement.clientWidth < 1024) {
                    let images = Array.from(document.querySelectorAll('.team-title'))
                    let max = Math.max(...images.map((x) => x.offsetHeight));
                    images.forEach((x) => x.style.height = `${max}px`);
                }
            },
        },
        mousewheel: {
            // enabled: false,
            invert: false,
        },
    });

    global.teamSwiper.on('resize', debounce(function(){
        if (document.querySelector('.team-swiper-container')) {
            teamSwiper.params.breakpoints = breakpoints[get_variant()];
            teamSwiper.update();
        }
    }, 150));

    // Team slider status
    global.TEAM = {
        isStart: true,
        isEnd: false,
    };

    global.teamSwiper.on('slideChangeTransitionStart', function() {
        window.ANI.accept = false;
    });

    global.teamSwiper.on('slideChangeTransitionEnd', debounce(function() {
        setTimeout(function(){
            global.TEAM.isStart = global.teamSwiper.isBeginning;
            global.TEAM.isEnd = global.teamSwiper.isEnd;
            window.ANI.accept = true;
        }, 1000);

    }, 150));

    const unit_name = document.querySelector('.team-more-left strong');
    const unit_poisition = document.querySelector('.team-more-left span');
    const unit_text = document.querySelector('.team-more-right div');

    let active_unit = null;
    let box = null;

    function team_item_click() {
        if (window.SYS.width >= 1024) {
            active_unit = this;
            box = $(active_unit).closest('.team-container');

            if (box.hasClass('show-user-info')) {
                box.removeClass('show-user-info');
                box = null;
            } else {
                unit_name.innerText = active_unit.dataset.name;
                unit_poisition.innerText = active_unit.dataset.poisition;
                unit_text.innerHTML = active_unit.dataset.text;
                box.addClass('show-user-info');
            }
        }
    }
    document.querySelectorAll('.team-item').forEach((x) => x.addEventListener('click', team_item_click));

    document.querySelectorAll('.team-photo').forEach((node) => {
        node.addEventListener('mouseover', () => {
            if (box && active_unit !== node.closest('.team-item') && box) {
                box.removeClass('show-user-info');
            }
            let date = new Date();
            let img = node.querySelector('img');
            img.src = img.src.split('?')[0] + `?${date.getTime()}`;
        })
    });

    const teamSection = document.querySelector('.team-section');
    if (teamSection) {
        let point0, point1;
        let prev_event;

        teamSection.addEventListener('touchstart', (event) => {
            point0 = event.changedTouches[0];
        });

        teamSection.addEventListener('touchend', (event) => {
            point1 = event.changedTouches[0];
            let deltaY = point1.clientY - point0.clientY;
            let deltaX = point1.clientX - point0.clientX;
            // let direction = deltaY < 0 ? 'down' : 'up';

            // console.log(deltaX);

            // if (Math.abs(deltaY) < 20) {
            //     return;
            // }

            // if (direction === 'down' && !global.TEAM.isEnd) {
            //     global.teamSwiper.slideNext();
            // } else if (direction === 'up' && !global.TEAM.isStart) {
            //     global.teamSwiper.slidePrev();
            // }

            let direction = deltaX < 0 ? 'left' : 'right';

            if (Math.abs(deltaX) < 20 || (Math.abs(deltaX) < Math.abs(deltaY))) {
                return;
            }

            if (direction === 'left' && !global.TEAM.isEnd) {
                // window.ANI.accept = false;
                global.teamSwiper.slideNext();
            } else if (direction === 'right' && !global.TEAM.isStart) {
                // window.ANI.accept = false;
                global.teamSwiper.slidePrev();
            }


        });

    }

};
