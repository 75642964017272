import axios from 'axios';
import Choices from 'choices.js';
import SimpleBar from 'simplebar';
// import { createApp } from 'vue/dist/vue.esm-bundler';
import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
import VueTheMask from 'vue-the-mask';
// import { createApp } from 'vue/dist/vue.esm-bundler';

Vue.use(TurbolinksAdapter);
Vue.use(VueTheMask);

const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const re_email = /\S+@\S+\.\S+/;
let user_data = {};
let len = 0;

function isValidHttpUrl(string) {
    // let url;
    // try {
    //   url = new URL(string);
    // } catch (_) {
    //   return false;
    // }
    // return url.protocol === 'http:' || url.protocol === 'https:';

    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(string);
}


const formStartups = {
    el: '#form_startups',

    data() {
        return {
            data: user_data,
            index: 1,
            // index: 'confirm',
        }
    },

    // template: '#form-startup-template',

    mounted() {
        // let infobox = document.querySelector('.develop-over');
        // if (infobox) {
        //     new SimpleBar(infobox, {
        //         autoHide: false,
        //      });
        // }

        let options = {
            searchEnabled: false,
            shouldSort: false,
            position: 'bottom',
            placeholder: false,
            itemSelectText: '',
        };

        document.querySelectorAll('#form_startups select').forEach((node) => {
            let choise = new Choices(node, options);
        });

        // document.querySelectorAll('#form_startups .choices__list--dropdown .choices__list').forEach((node) => {
        //     new SimpleBar(node, { autoHide: false });
        // });
    },

    computed: {
        validate: function() {
            let self = this;
            let current_id = window.questions[self.index - 1];
            let current_type = window.questions_types[self.index - 1];

            if (current_type === '1') {
                return self.data[current_id] === '';
            } else if (current_type === '4') {
                return !isValidHttpUrl(self.data[current_id]);
            } else if (current_type === '3') {
                return !re_email.test(self.data[current_id]);
            } else {
                return self.data[current_id] === '';
            }
        }
    },

    methods: {
        next: function() {
            let self = this;
            if (self.index < len) {
                self.index += 1;

                setTimeout(() => {
                    try {
                        self.$el.querySelector(`#question_${self.index}`).focus();
                    } catch(e) {
                        // empty
                    }
                }, 250);

            } else {
                self.index = 'confirm';
            }
        },
        goto: function(idx) {
            if (idx <= len) {
                this.index = idx;
            }
        },
        checkme: function(event) {
            const self = this;
            let current_id = window.questions[self.index - 1];
            let current_type = window.questions_types[self.index - 1];
            let parent = event.target.parentNode;

            if (current_type === '1') {

                if(self.data[current_id] === '') {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }

            } else if (current_type === '4') {

                if( !isValidHttpUrl(self.data[current_id]) ) {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }

            } else if (current_type === '3') {

                if( !re_email.test(self.data[current_id]) ) {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }

            } else {

                if (self.data[current_id] === '') {
                    parent.classList.add('input-error-class');
                } else {
                    parent.classList.remove('input-error-class');
                }
            }
        },
        send: function(event) {
            const self = this;
            axios.post(event.target.action, self.data)
            .then((response) => {
                const resp = response.data;
                if (resp.status === 'ok') {
                    self.index = 'post-message'
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        close: function() {
            document.body.classList.remove('show-startups-form');
        },
    }
}


const form_startups = () => {

    if(!window.questions || !document.getElementById('form_startups')) {
        return;
    }

    //*
    window.questions.forEach((x) => user_data[x] = '');
    // window.questions.forEach((x) => user_data[Object.keys(x)[0]] = '');
    /*/
    window.questions.forEach((x) => user_data[x] = `${x}`.repeat(x) );
    //*/
    len = window.questions.length;
    // createApp(formStartups).mount('#form_startups');

    let app = new Vue(formStartups);

    // const app = createApp(formStartups);
    // app.use(VueTheMask);
    // app.mount('#form_startups');
};

export default form_startups;
