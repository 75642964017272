import { unlock, replace_body_state } from './utils';
import mainSquare from "./mainSquare";
import {showTitlesPrevSubsection} from './step_prev';


let oneOut_twoIn = (status=false, hideTitle=false) => {

    let nodes = window.ANI.el;
    let curernt_index = window.ANI.idx.subsection;

    if(status) {
        curernt_index -= 1;
        window.ANI.idx.subsection = curernt_index;
    }
    let next_index = curernt_index + 1;
    let hideAnim = new Promise((resolve, reject) => {
        !status ? mainSquare('moveTop', resolve) : resolve();
       /* mainSquare('moveTop', resolve);*/
        nodes.fa11
            .velocity({ transform: ['translateX(-100vw)', 'translateX(0)'] }, { duration: 1250 })
            .velocity({ opacity: [0, 1] }, { duration: 250 });

        nodes.fa12
            .velocity({ transform: ['translateX(100vw)', 'translateX(0)'] }, { duration: 1250 })
            .velocity({ opacity: [0, 1] }, {
                duration: 250,
                complete: function() {
     /*               document.querySelector('.fa03 span')
                        .velocity({
                            opacity: [0, 1],
                        }, {
                            duration: 250,
                            complete: () => {
                                mainSquare('moveTop', resolve);
                                !status ? mainSquare('moveTop', resolve) : resolve();
                            },
                        });*/
                }
            });
    });

    let showBg = new Promise((resolve, reject) => {
        nodes.fa22.velocity({ opacity: [1, 0] }, {
            duration: 150,
            complete: () => {
                resolve();
            },
        });
    });




    hideAnim
    .then(() => showBg)
    .then(() => {
        nodes.promo.style.transform = `translateY(-${next_index * 100}%)`;
       /* !status ? nodes.promo_slides[1].style.top = '200%': null;*/
        let properties = {
            opacity: [1, 0],
            transform: ['translateY(0)', 'translateY(100%)'],
        };

        let duration = 250;
        let len = nodes.fa21.length - 1;
        function wrapper() {
            showTitlesPrevSubsection();
            window.ANI.idx.subsection = next_index;
            replace_body_state();
            unlock();
        }

       !hideTitle ? nodes.fa21.forEach((node, index) => {
            if (index < len) {
               node.velocity(properties, {
                    duration: duration,
                    delay: duration * index,
                });
            } else {
                node.velocity(properties, {
                    duration: duration,
                    delay: duration * index,
                    complete: () => {
                        window.ANI.idx.subsection = next_index;
                        replace_body_state();
                        unlock();
                    }
                });
            }
        }) : wrapper();

    })
}

export default oneOut_twoIn;
