import * as THREE from "three";
import {
    EffectComposer,
    EffectPass,
    RenderPass,
    SavePass,
    BlendFunction,
    NoiseEffect,
    ToneMappingEffect,
    ToneMappingMode
} from "postprocessing";
import twoOut_oneIn from "./twoOut_oneIn";
import oneOut_twoIn from "./oneOut_twoIn";
import {next_section} from "./step_next";
import background from "./background";


function mainSquare(type, callback, flag = false) {

    sessionStorage.removeItem('status');

    const canvas = document.querySelector('#square');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let startPosition = window.innerWidth < 700  ? 1400 /2 : window.innerWidth / 2;
    let positionY = window.innerHeight + 10;
    let nearCam = window.innerWidth > 500 ? 3.5 : 2.2;
    //let nearCam = 3.5;

    let baseProperty = {camera: nearCam, box: {x: -startPosition, y: 0}, light: {x: -200, y: 650, z: 200}};
    let bottomProperty = {camera: 3.5, box: {x: 0, y: positionY}, light: {x: 1600, y: 110, z: 410}};
    let zoomProperty = {camera: 1.5, box: {x: 0, y: 0}, light: {x: 1600, y: 110, z: 410}};

    let property;
    let nodes = window.ANI.el;

    switch (type) {
        case 'base':
            property = baseProperty;
            break;
        case 'moveTop':
            property = baseProperty;
            break;
        case 'moveBottom':
            property = bottomProperty;
            break;
        case 'zoom':
            property = zoomProperty;
            break;
        case 'rotate':
            property = zoomProperty;
            break;
    }

    const renderer = new THREE.WebGLRenderer({
        canvas,
        alpha: true,
        depth: false,
        physicallyCorrectLights: true,
    });

    //---init scene, camera, material

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    let positionCam = property.camera;

    const scene = new THREE.Scene();
    let camera = new THREE.OrthographicCamera(canvas.width / -positionCam, canvas.width / positionCam, canvas.height / positionCam, canvas.height / -positionCam, 1, 1000);
    if (type === 'base' || type === 'moveTop') {
        camera.rotation.z = 0.7
    };
    const color = 0xFFFFFF;
    scene.add(camera);

    const skyColor = 0xc7cccd;
    const groundColor = 0xe4f0f2;

    let intensity = 3.2;

    if(type === 'zoom' && window.innerWidth < 500) intensity = 0.25;

    const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
    scene.add(light);

    function getGeometry(width, height, depth) {
        const geometry = new THREE.PlaneGeometry(width, height);
        return geometry;
    }

    let texture = new THREE.Texture(generateTexture(160, 0));
    texture.needsUpdate = true;

    function generateTexture(num1, num2) {

        let size = window.innerWidth;

        let canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;

        let context = canvas.getContext('2d');
        context.rect(0, 0, size , size);

        let gradient = context.createLinearGradient(0, 0, size, 0);

        gradient.addColorStop(0, `rgba(${num2}, ${num2}, ${num2}, 0.3)`);
        gradient.addColorStop(0.35, `rgba(15, 15, 15, 0.3)`);
        gradient.addColorStop(0.40, `rgba(20, 20, 20, 0.3)`);
        gradient.addColorStop(0.47, `rgba(50, 50, 50, 0.3)`);
        gradient.addColorStop(0.52, `rgba(100, 100, 100, 0.3)`);
        gradient.addColorStop(0.60, `rgba(120, 120, 120, 0.3)`);
        gradient.addColorStop(0.70, `rgba(135, 135, 135, 0.3)`);
        gradient.addColorStop(0.93, `rgba(145, 145, 145, 0.3)`);
        gradient.addColorStop(1, `rgba(${num1}, ${num1}, ${num1}, 0.3`);

        context.fillStyle = gradient;
        context.fill();
        return canvas;
    };


    function makeInstance(geometry, color, x, y, z, val) {
        const material = new THREE.MeshPhongMaterial({
            color,
            vertexColors: true,
            flatShading: true,
            side: THREE.DoubleSide,
            shininess: 0,
            map: texture,
            specular: 0x000000,
        });

        const cube = new THREE.Mesh(geometry, material);
        scene.add(cube);

        cube.position.x = x;
        cube.position.y = y;
        cube.position.z = z;
        if (type === 'moveBottom') {
            cube.rotation.z = 1.58;
        }
        return cube;
    }

    function generateCubes() {
        let arrCubes = [];
        let width = window.innerWidth < 700 ? window.innerHeight * 2 : window.innerWidth + window.innerWidth / 2 + 400;

        for(let i = 300, level = -1; i <= width + 1000; i += 200, level -= 0.1 ) {
         arrCubes.push(makeInstance(getGeometry(i, i), 'rgb(113,109,109)', posX, posY, level));
        }
        return arrCubes;
    };


    let posX = property.box.x;
    let posY = property.box.y;
    const cubes = generateCubes();

    renderer.render(scene, camera);
    renderer.shadowMap.enabled = true;

    //-----------------init postprocessing

    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    const savePass = new SavePass();


    const noiseEffect = new NoiseEffect({
        blendFunction: BlendFunction.REFLECT,
    });
    if(type != 'base') {
        noiseEffect.blendMode.opacity.value = 0.5;
    }
    if(type === 'zoom' && window.innerWidth < 500) {
        light.intensity = 0.25;
        noiseEffect.blendMode.opacity.value = 0.3;
    }


    const effectPass = new EffectPass(camera, noiseEffect);

    const toneMappingEffect = new ToneMappingEffect({
        mode: ToneMappingMode.REINHARD2_ADAPTIVE,
        resolution: 256,
        whitePoint: 16,
        minLuminance: 0.01,
        averageLuminance: 0.01,
        adaptationRate: 3,
    });

    const effectTone = new EffectPass(camera, toneMappingEffect);
    composer.addPass(effectTone);
    composer.addPass(savePass);
    if(type !== 'base' && type !== 'moveTop') {
        composer.addPass(effectPass);
    }
    composer.setSize(canvas.width, canvas.height);
    composer.render();

    function initEffects() {
        composer.addPass(effectPass);
    }

    //----------animations

    function toggleStatusAnimation(status) {
        let formatted = JSON.stringify(status);
        if (status) {
            sessionStorage.setItem('status', formatted);
        } else {
            sessionStorage.removeItem('status');
        }
    };

    function initStatus() {
        let status = JSON.parse(sessionStorage.getItem('status'));
        if (!status) {
            let data = {type: 'active', direction: false};
            setTimeout(() => toggleStatusAnimation(data), 300);
        }
    };

    let start = performance.now();

    function timing(timeFraction) {
        return Math.pow(timeFraction, 3);
    };

    //let duration = window.innerWidth > 500 ? 1500 : 1000;

    function activateAnimation({helper, update, direction, reverseUpdate, duration}) {

        function reverseAnimation(value) {
            let step = value;
            function reverse() {
                step -= 0.02;
                if(step < 0 ) step = 0;
                helper(step);
                let id = requestAnimationFrame(reverse);
                if(step == 0) {
                    window.cancelAnimationFrame(id);
                    reverseUpdate();
                }
            }
           setTimeout(() => requestAnimationFrame(reverse), 100);
        }

        function animate(time) {
            let timeFraction = (time - start) / duration;
            let activeStatus = JSON.parse(sessionStorage.getItem('status'));
            let progress = timing(timeFraction);
            helper(progress);
            let id = requestAnimationFrame(animate);

            function breakAnimation(condition) {
                if (condition) {
                    duration = 1000;
                } else {
                    cancelAnimationFrame(id);
                    reverseAnimation(progress);
                }
            }

            if (activeStatus && activeStatus.type === 'no-active') {
                if (activeStatus.direction === 'up') {
                    breakAnimation(direction);
                } else {
                    breakAnimation(!direction);
                }
            }

            if (timeFraction > 1) {
                timeFraction = 1;
                update();
                toggleStatusAnimation();
                cancelAnimationFrame(id);
            };
        }
        requestAnimationFrame(animate);
    };



    function animationCamera() {
        initStatus();
        initEffects();

        function updatePage() {
            if(window.innerWidth > 500) {
                //background('main', 0.15);
                mainSquare('zoom', null, true);
            }
            callback();
        };

        function toPrevPage() {
          twoOut_oneIn(true);
        }

        activateAnimation({
            helper: draw,
            update: updatePage,
            direction: false,
            reverseUpdate: toPrevPage,
            duration: window.innerWidth > 500 ? 1500 : 1000,
        });

        function draw(progress) {
            if (camera.rotation.z >= 0) {
                camera.rotation.z = 0.7 - 0.7 * progress;
            }
            if (camera.rotation.z <= 0) {
                camera.rotation.z = 0;
            }

            cubes.forEach((cube) => {
                cube.position.x = -startPosition + (startPosition * progress);
                if(cube.position.x > 0) cube.position.x = 0;
            });

            let posCamLeft = (canvas.width / -nearCam) - ((canvas.width / -nearCam) - (canvas.width / -1.5)) * progress;
            let posCamRight = (canvas.width / nearCam) - ((canvas.width / nearCam) - (canvas.width / 1.5)) * progress;
            let posCamTop = (canvas.height / nearCam) - ((canvas.height / nearCam) - (canvas.height / 1.5)) * progress;
            let posCamBottom = (canvas.height / -nearCam) - ((canvas.height / -nearCam) - (canvas.height / -1.5)) * progress;

            camera.left = posCamLeft;
            camera.right = posCamRight;
            camera.top = posCamTop;
            camera.bottom = posCamBottom;

            if(window.innerWidth > 500) {
                noiseEffect.blendMode.opacity.value = 0.5 * progress;
                effectTone.minLuminance = 0.01 + 0.03 * progress;
            } else {
                light.intensity = 3.2 - 2.95 * progress;
                noiseEffect.blendMode.opacity.value = 0.3 * progress;
            }

            camera.updateProjectionMatrix();
            renderer.render(scene, camera);
            composer.render();
        }
    };


    function renderNextSection() {
        initStatus();

        if(window.innerWidth > 500) {
            effectTone.minLuminance = 0.4;
            noiseEffect.blendMode.opacity.value = 0.5;
        } else {
            light.intensity = 0.25;
            noiseEffect.blendMode.opacity.value = 0.3;
        }

        renderer.render(scene, camera);
        composer.render();

        function draw(progress) {
            nodes.fa03.style.backgroundColor = `rgba(90, 90, 90, ${0.3 * progress})`;
            cubes.forEach((cube, ndx) => {
                cube.rotation.z = 1.58 * progress;
            });
            cubes.forEach((cube, ndx) => {
                cube.position.y = positionY * progress;
                if(cube.position.y > positionY) cube.position.y = positionY;
            });
            let posCamLeft = (canvas.width / -1.5) - ((canvas.width / -1.5) - (canvas.width / -3.5)) * progress;
            let posCamRight = (canvas.width / 1.5) - ((canvas.width / 1.5) - (canvas.width / 3.5)) * progress;
            let posCamTop = (canvas.height / 1.5) - ((canvas.height / 1.5) - (canvas.height / 3.5)) * progress;
            let posCamBottom = (canvas.height / -1.5) - ((canvas.height / -1.5) - (canvas.height / -3.5)) * progress;

            camera.left = posCamLeft;
            camera.right = posCamRight;
            camera.top = posCamTop;
            camera.bottom = posCamBottom;

            if(window.innerWidth > 500) {
                noiseEffect.blendMode.opacity.value = 0.5 - 0.5 * progress;
            } else {
                noiseEffect.blendMode.opacity.value = 0.3- 0.3 * progress;
            }

            camera.updateProjectionMatrix();
            renderer.render(scene, camera);
            composer.render();
        };

        function updatePage() {
            callback();
        }

        function reverseUpdate() {
          oneOut_twoIn(true, true);
        }

        activateAnimation({
            helper: draw,
            update: updatePage,
            direction: false,
            reverseUpdate,
            duration: window.innerWidth > 500 ? 1500 : 500,
        });

    };


    function moveBottom() {
        initStatus();

        nodes.fa03.style.backgroundColor = `rgba(90, 90, 90, 0.3)`;

        if(window.innerWidth > 500) {
            effectTone.minLuminance = 0.4;
        } else {
            light.intensity = 0.25;
        }
        composer.render();

        function updatePage() {
            mainSquare('zoom', null, true);
        };

        function reverseUpdate() {
            const ani = window.ANI;
            next_section(ani, true);
        }

        function draw(progress) {

            nodes.fa03.style.backgroundColor = `rgba(90, 90, 90, ${0.3 - 0.3 * progress})`;
            cubes.forEach((cube, ndx) => {
                cube.position.y = positionY - positionY * progress;
                if(cube.position.y < 0) cube.position.y = 0
            });

            let posCamLeft = (canvas.width / -3.5) - ((canvas.width / -3.5) - (canvas.width / -1.5)) * progress;
            let posCamRight = (canvas.width / 3.5) - ((canvas.width / 3.5) - (canvas.width / 1.5)) * progress;
            let posCamTop = (canvas.height / 3.5) - ((canvas.height / 3.5) - (canvas.height / 1.5)) * progress;
            let posCamBottom = (canvas.height / -3.5) - ((canvas.height / -3.5) - (canvas.height / -1.5)) * progress;

            camera.left = posCamLeft;
            camera.right = posCamRight;
            camera.top = posCamTop;
            camera.bottom = posCamBottom;

            cubes.forEach((cube, ndx) => {
                cube.rotation.z = 1.58 - 1.58 * progress;
                if(cube.rotation.z < 0)  cube.rotation.z = 0;
            });

            noiseEffect.blendMode.opacity.value = 0.5 * progress;
            if(window.innerWidth > 500) {
                noiseEffect.blendMode.opacity.value = 0.5 * progress;
            } else {
                noiseEffect.blendMode.opacity.value = 0.3 * progress;
            }

            camera.updateProjectionMatrix();
            renderer.render(scene, camera);
            composer.render();
        };

        activateAnimation({
            helper: draw,
            update: updatePage,
            direction: true,
            reverseUpdate,
            duration: window.innerWidth > 500 ? 1500 : 1000,
        });
    };


    function renderPrevSection() {
        initStatus();

        if(window.innerWidth > 500) {
            noiseEffect.blendMode.opacity.value = 0.5;
            effectTone.minLuminance = 0.4;
        } else {
            noiseEffect.blendMode.opacity.value = 0.3;
            light.intensity = 0.25;
        }
        composer.render();

        function updatePage() {
            mainSquare('base', null, true);
            callback();
        }

        function reverseUpdate() {
           oneOut_twoIn(true);
        }

        activateAnimation({
            helper: draw,
            update: updatePage,
            direction: true,
            reverseUpdate,
            duration: window.innerWidth > 500 ? 1500 : 1000,
        });

        function draw(progress) {

            let posCamLeft = (canvas.width / -1.5) - ((canvas.width / -1.5) - (canvas.width / -nearCam)) * progress;
            let posCamRight = (canvas.width / 1.5) - ((canvas.width / 1.5) - (canvas.width / nearCam)) * progress;
            let posCamTop = (canvas.height / 1.5) - ((canvas.height / 1.5) - (canvas.height / nearCam)) * progress;
            let posCamBottom = (canvas.height / -1.5) - ((canvas.height / -1.5) - (canvas.height / -nearCam)) * progress;

            camera.left = posCamLeft;
            camera.right = posCamRight;
            camera.top = posCamTop;
            camera.bottom = posCamBottom;

            camera.rotation.z = 0.7 * progress;

            cubes.forEach((cube, ndx) => {
                cube.position.x = -startPosition * progress;
                if(cube.position.x < -startPosition) cube.position.x = -startPosition;
            });

            noiseEffect.blendMode.opacity.value = 0.5 - 0.5 * progress;
            if(window.innerWidth > 500) {
                noiseEffect.blendMode.opacity.value = 0.5 - 0.5 * progress;
                effectTone.minLuminance = 0.04 - 0.03 * progress;
            } else {
                noiseEffect.blendMode.opacity.value = 0.3 - 0.3 * progress;
                light.intensity = 0.25 + 2.95 * progress;
            }
            camera.updateProjectionMatrix();
            renderer.render(scene, camera);
            composer.setSize(window.innerWidth, window.innerHeight);
            composer.render();
        }
    }


    if (!flag) {
        switch (type) {
            case 'moveTop':
                animationCamera();
                break;
            case 'rotate':
                renderNextSection();
                break;
            case 'moveBottom':
                moveBottom();
                break;
            case 'zoom':
                renderPrevSection();
                break;
        }
    }

    window.ANI.el.box.addEventListener('wheel', (event) => {
        let status = JSON.parse(sessionStorage.getItem('status'));
        if (status && status.type === 'active') {
            let delta = parseInt(event.wheelDelta || -event.detail);
            let data = {type: 'no-active'}
            if (delta >= 0) {
                data.direction = 'up';
            } else {
                data.direction = 'down';
            }
            toggleStatusAnimation(data);
        }
    });

};

export default mainSquare;
